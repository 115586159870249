<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-6">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										<!-- <input v-model="query" class="form-control p-2" placeholder="企业搜索" type="text"> -->
										<span>
											<select v-model="selected_dept_id" class="form-control">
												<option value="0">所有企业</option>
												<option v-bind:key="index" 
													:value="item.id" v-for="(item,index) in departmentlist">
													{{item.department_name}}</option>
											</select>
										</span>
										<button @click="search" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-6 ">
									<div class="float-right m-3">
										<button  @click="add" type="button" class="btn btn-secondary">额度充值</button>
										<button  @click="viewLogs" type="button" class="btn btn-secondary ml-2">充值记录</button>
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" id="checkedAll" class="custom-control-input"><label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">企业名称</div>
											</th>
											<!-- <th>
												<div class="d-none d-lg-block">
													额度类型
													<i v-tooltip="'2024-07-01后新增的企业，只有弹性福利额度'" class="icon icon-question-circle" />
													</div>
											</th> -->
											
											<th>
												<div class="d-none d-lg-block">累计充值(元)</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">已用额度(元)</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">剩余可用额度(元)</div>
											</th>
											
											<!-- <th></th> -->
										</tr>
									</thead>
	
									<tbody>
										<tr v-for="(item,index) in quotalist" v-bind:key="index" v-show="item.is_default != 1" 
										:class="{'blue lighten-5':selectedId == item.id}">
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input checkSingle" id="user_id_32" required=""><label class="custom-control-label" for="user_id_1"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
													<template v-if="deptmap.hasOwnProperty(item.dept_id)">
														{{deptmap[item.dept_id].department_name}}
													</template>
												</div>
											</td>
											
											<!-- <td>
												<div class="d-none d-lg-block">{{item.expense_name}}</div>
											</td> -->
											
											<td>
												<div class="d-none d-lg-block">{{item.total_pay.toFixed(2)}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.total_used.toFixed(2)}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.total_available.toFixed(2)}}</div>
											</td>
											
											<!-- <td>
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs"> 编辑 </button>
																								 
											</td> -->
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<!-- <div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div> -->
	
		</div>
		
		<!--Add New Message Fab Button-->
		<div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div>
		
		<!-- modal start -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">额度充值</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
		          
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">归属企业</label>
				  	<select v-model="quota.dept_id" class="form-control">
				  		<option value="">选择企业</option>
						<template v-for="(item,index) in departmentlist">
				  		<option v-bind:key="index"
				  			:value="item.id" v-if="item.enabled==1">
				  			{{item.department_name}}</option>
						</template>	
				  	</select>
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
				  <!-- <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">额度类型</label>
				  	<select v-model="quota.expense_type" class="form-control">
						<option value="">选择费用类型</option>
				  		<option v-bind:key="index" :value="item.value"
				  			v-for="(item,index) in categorylist">{{item.name}}</option>
				  	</select>
				  	<div class="s-12">
				  	</div>	
				  </div> -->
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">充值金额(元)</label>
				  	<input v-model="quota.pay" type="number" class="form-control"
				  		placeholder="充值金额">
				  	<div class="s-12 text-danger">
						<!-- 充值正数增加额度，充负数减少额度。 -->
						最多充值不超过 {{ent_quota.total_available}} 元
				  	</div>	
				  </div>
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">审批验证码</label>
				  	<div class="input-group">
				  	  <input v-model="valid_code" type="number" class="form-control" placeholder="输入验证码" aria-describedby="button-addon2">
				  	  <div class="input-group-append">
				  	    <button @click="sendMessage" 
						:disabled="disabled" 
						v-show="show"
						class="btn btn-primary" type="button" id="button-addon2">发送</button>
						
						<button v-show="!show" class="btn btn-secondary">{{seconds}}秒后重发</button>
						
				  	  </div>
				  	</div>
				  	<div class="s-12">
				  		发送成功后，请向审批人员索取
				  	</div>	
				  </div>
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">备注(可选)</label>
				  	<input v-model="quota.remark" type="text" class="form-control"
				  		placeholder="充值备注">
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				 <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- modal end -->
		
	</div>		
</template>

<script>
// import VPagination from '../components/my-page.vue'
export default {
    data: function() {
        return {
			title: '企业',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			user_role: '',
			user_name: '',
			
			quotalist: [],
			query: '',
			
			department: {},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			quota: {},
			// expensetypelist: [
			// 	{name: '选择费用类型', value: ''},
			// 	{name: '弹性福利', value: 'DEFAULT'},
			// 	{name: '餐食外卖', value: 'MEAL'},
			// 	{name: '交通出行', value: 'CAR'},
			// 	{name: '工会福利', value: 'COMMON'},
			// ],
			categorylist: this.GLOBAL.categorylist,
			
			////
			departmentlist: [],
			// dept_id: '',
			deptmap: {}, //key为 dept id, value为 dept
			
			expenseTypeMap: {
				'DEFAULT': '弹性福利',
				'MEAL':'餐食外卖',
				'CAR':'交通出行',
				'COMMON': '工会福利',
			},
			
			selectedQuotaList: [],
			selected_dept_id: 0,
			
			////
			valid_code: '',
			show: true,
			seconds: '',
			timer: null,
			
			////
			current_page: 1,
			page_size: 50,
			count: 0,
			total_page: 0,
			show_from: 0,
			show_to: 0,
			
			total: 0, // 记录总条数
			display: 10, // 每页显示条数
			current: 1, // 当前的页数
			
			////
			ent_quota: {
				total_available: 0,
			},
        }
    },
    props: {
      
    },
	
	components: {
		// VPagination
	},
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		// self.dept_id = lsLoginData.dept_id;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.init(() => {
			self.initData();
		})
	},
    methods:{
		init(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
					params: {
						token: self.token,
						dept_id: self.dept_id,
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						self.current_date = data.data.current_date;
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
		
    	initData() {
    		let self = this;
    	
    		self.loader = self.$loading.show();
			
			console.error('***', self.current_page, self.page_size);
    		
    		this.axios.get(this.GLOBAL.baseURI + 'getDeptQuotaList', {
    				params: {
    					token: self.token,
    					dept_id: self.dept_id,
    					// sub_dept_id: self.sub_dept_id,
    					query: self.query,
    					current_page: self.current_page || 1,
    					page_size: self.page_size || 50,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				self.loader.hide();
    				
    				if (data.code == 200) {
    					self.quotalist = data.data.list;
						self.selectedQuotaList = data.data.list;
						
    					console.error('--- self.quotalist ->', self.quotalist)
    					
    		
    					self.current_page = data.data.page_num;
    					self.page_size = data.data.page_size;
    					self.count = data.data.total;
    					self.total_page = Math.ceil(self.count / self.page_size);
    					console.log('--- self.total_page ->', self.total_page)
    					
    					self.show_from = self.page_size * (self.current_page - 1) + 1;
    					self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
    						.current_page : self.count;
    					
    					self.total = self.count; // 记录总条数
    					self.display = self.page_size; // 每页显示条数
    					self.current = self.current_page; // 当前的页数
    				} 
    				else if (data.code == 403) {
    					 // Vue.toasted.error( '没有权限', {
    					 // 	position: 'top-center',
    					 // 	duration: '2000',
    					 // })
    					 
    					 self.$router.push({ name: '403'});
    					 
    					 return;
    				}
    				else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
    					self.logoff();
    				} 
    				else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    		let self = this;
			console.log('---- self.selectedQuotaList->',self.selectedQuotaList)
			
			if(self.selected_dept_id==0){
				self.quotalist = self.selectedQuotaList;
			}
			else{
				self.quotalist = self.selectedQuotaList.filter(item => item.dept_id==self.selected_dept_id);
			}
    		
    	},
    	
    	add() {
    		var self = this;
    	
			self.getEntQuota(()=>{
				self.selectedIndex = -1;
				self.quota = {
					id: 0,
					dept_id: '',
					expense_type: '',
					pay: '',
					reamrk: '',
				};
				
				$('#modal').modal('show');
			})
    	},
		
		viewLogs(){
			var self = this;
				
			this.$router.push({ name: 'quotalogs', params: { ent_id: self.ent_id }})//【FANDY】 视为 POST 提交
		},
    	
    	getEntQuota(callback) {
    		let self = this;
    	
    		this.axios.get(this.GLOBAL.baseURI + 'getEntQuota', {
    				params: {
    					token: self.token
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				self.loader.hide();
    				
    				if (data.code == 200) {
						if(data.data.quota != undefined){
							self.ent_quota = data.data.quota;
						}
						
						console.log('--- self.ent_quota ->',self.ent_quota);
						
						if (typeof callback == 'function') {
						    callback()
						}
    				} 
    				else if (data.code == 403) {
    					 // Vue.toasted.error( '没有权限', {
    					 // 	position: 'top-center',
    					 // 	duration: '2000',
    					 // })
    					 
    					 self.$router.push({ name: '403'});
    					 
    					 return;
    				}
    				else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
    					self.logoff();
    				} 
    				else {
    					// alert(data.message)
						Vue.toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
    				}
    			});
    		//END
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.quota);
			
			if(self.disabled) return;
    	
    		console.log('--- self.quota ->', self.quota)
    		
    		if (!self.quota.dept_id) {
    			self.$toasted.error('请选择归属企业', {
    				position: 'top-center',
    			}).goAway(2000)
    			return;
    		}
    		
    		// if (!self.quota.expense_type) {
    		// 	self.$toasted.error('请选择额度类型', {
    		// 		position: 'top-center',
    		// 	}).goAway(2000)
    		// 	return;
    		// }
    		
    		if (!self.quota.pay) {
    			self.$toasted.error('请输入充值金额', {
    				position: 'top-center',
    			}).goAway(2000)
    			return;
    		}
			
			if (!self.valid_code) {
				self.$toasted.error('请输入审批验证码', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
			
			
    		
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
    			dept_id: self.quota.dept_id,//添加时为0
    			expense_type: 'DEFAULT',//self.quota.expense_type,//固定用DEFAULT
				pay: self.quota.pay,
				remark: self.quota.remark,
				nonce_str: '',//预留
				
				valid_code: self.valid_code,
    		}
			
			console.log('--- postData ->',postData)
    	
    		this.axios.get(this.GLOBAL.baseURI + 'addDeptQuota', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						if(data.data.result_code == 'success'){
							
							self.$toasted.success('充值成功', {
								position: 'top-center',
							}).goAway(2000)
							
							self.initData();
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    					
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
						self.logoff();
    				} 
					else {
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
    				}
    			});
    	},
		
		sendMessage(){
    		let self = this;
			
			// self.$toasted.success('发送成功', {
			// 	position: 'top-center',
			// }).goAway(2000)
			
			// //倒计时
			// self.countdown();
			
			if(self.disabled) return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    		}
			
			console.log('--- postData ->',postData)
    	
    		this.axios.get(this.GLOBAL.baseURI + 'sendEnterpriseQuotaMessage', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						self.$toasted.success('发送成功', {
							position: 'top-center',
						}).goAway(2000)
						
						//倒计时
						self.countdown();
    				} 
					else if (data.code == 403) {
						 self.$router.push({ name: '403'});
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
						self.logoff();
    				} 
					else {
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
    				}
    			});
    	},
		
		countdown() {
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.seconds = TIME_COUNT;
				this.show = false;
				this.timer = setInterval(() => {
					if (this.seconds > 0 && this.seconds <= TIME_COUNT) {
						this.seconds--;
					} else {
						this.show = true;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000)
			}
		},
		
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
		
		pagechange: function(currentPage) {
			console.log(currentPage);
			// ajax请求, 向后台发送 currentPage, 来获取对应的数据
			this.current_page = currentPage;
			this.initData();
		},
    }
}
</script>

<style>
</style>
